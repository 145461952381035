import { useQuery } from '@apollo/client';
import { useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { AUTHENTICATION_METHOD } from 'constants/app';
import { CHECK_PERMISSION } from 'queries';
import { notificationState } from 'states/notification';
import { errorWithBackend } from 'utils/Helpers';

const useCheckAccessReport = () => {
  const navigate = useNavigate();
  const setNotification = useSetRecoilState(notificationState);
  const { accessReportId } = useParams();

  // Check if it has invalid mapping
  const { loading, error, data, refetch } = useQuery(CHECK_PERMISSION, {
    variables: { accessReportId: accessReportId },
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (error) {
      errorWithBackend(
        error,
        navigate,
        accessReportId,
        setNotification,
        refetch
      );
    }
  }, [accessReportId, error, navigate, setNotification, refetch]);

  const texts = useMemo(() => {
    switch (data?.result.authenticationMethod) {
      case AUTHENTICATION_METHOD.MOBILE_ID:
        return {
          title: 'MobileIdTitle',
          desc: 'MobileIdDescription',
        };

      case AUTHENTICATION_METHOD.SMS:
        return {
          title: 'SmsTitle',
          desc: 'SmsDescription',
        };

      default:
        return {
          title: 'EmailTitle',
          desc: 'EmailDescription',
        };
    }
  }, [data]);

  return {
    loading,
    error,
    accessReportId,
    data,
    texts,
    refetch,
  };
};

export default useCheckAccessReport;
