// Window

export const BACKEND_GRAPHQL_URL = window._env_.BACKEND_GRAPHQL_URL;

export const HEADER_URL = window._env_.HEADER_URL;

// Constants

export const TIME_BEFORE_RESEND_TOKEN = 3; // Minutes
export const TIME_BEFORE_TOKEN_EXPIRE = 10; // Minutes

export const AUTHENTICATION_METHOD = {
  MOBILE_ID: 'MOBILE_ID',
  EMAIL: 'EMAIL',
  SMS: 'SMS',
};

export const STATUS_TYPE = {
  CHANGED_TO_SMS: 'CHANGED_TO_SMS',
  SUCCESS: 'SUCCESS',
};

export const GRAPHQL_ERRORS = {
  ACCESS_REPORT_EXPIRED: 'AccessReportExpired',
  ACCESS_REPORT_LOCKED: 'AccessReportLocked',
  ACCESS_REPORT_NOT_FOUND: 'AccessReportNotFound',
  AUTHENTICATION_METHOD_INVALID: 'AuthenticationMethodInvalid',
  EMAIL_ERROR: 'EmailError',
  MOBILE_ID_AUTHENTICATION_FAILED: 'MobileIdAuthenticationFailed',
  MOBILE_ID_ERROR: 'MobileIdError',
  MOBILE_ID_GEOFENCING_FAILED: 'MobileIdGeofencingFailed',
  MOBILE_ID_NO_MOBILE_PHONE_NUMBER: 'MobileIdNoMobilePhoneNumber',
  MOBILE_ID_NOT_SUPPORTED: 'MobileIdNotSupported',
  SMS_ERROR: 'SmsError',
  TOKEN_INVALID: 'TokenInvalid',
  UNKNOWN_ERROR: 'UnknownError',
};

export const SESSION_STORAGE_KEYS = {
  DELAY_BUTTON: 'delayButton',
  SESSION_TOKEN: 'sessionToken',
};

// Server errors
export const ERRORS_SERVER_STATUS = [500, 501, 502, 503, 504];

export const ERROR_SERVER_TYPES = {
  SERVER_ERROR: 'serverError',
};

export const FORMAT_TYPES = {
  CSV: 'csv',
  PDF: 'pdf',
  PPT: 'ppt',
  PPTX: 'pptx',
  XLS: 'xls',
  XLSX: 'xlsx',
  ZIP: 'zip',
};

export const MIME_TYPES = {
  CSV: 'text/csv',
  PDF: 'application/pdf',
  PPT: 'application/vnd.ms-powerpoint',
  TXT: 'text/plain',
  XLS: 'application/vnd.ms-excel',
  ZIP: 'application/zip',
};
