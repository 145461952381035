import { memo } from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="footer footer--compact">
        <div className="container">
          <div className="logo">
            <span
              className="logo-lifeform"
              aria-label="Swisscom Logo"
              role="img"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default memo(Footer);
